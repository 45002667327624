import * as Sentry from '@sentry/nuxt'
import { Environment } from 'core'

if (Environment.PRODUCTION === import.meta.env.MODE) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [Sentry.replayIntegration()],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}
